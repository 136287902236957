var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      attrs: {
        bottom: "",
        left: "",
        "close-on-click": true,
        "close-on-content-click": false,
        absolute: _vm.type == "absolute",
        "position-x": _vm.x,
        "position-y": _vm.y,
      },
      scopedSlots: _vm._u(
        [
          _vm.type == "button"
            ? {
                key: "activator",
                fn: function ({ on, attrs }) {
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        _vm._b(
                          {
                            ref: "actionsButton",
                            attrs: {
                              icon: "",
                              right: "",
                              "aria-label": _vm.$t("dayActionsLabel"),
                            },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "right",
                                    39,
                                    $event.key,
                                    ["Right", "ArrowRight"]
                                  )
                                )
                                  return null
                                if ("button" in $event && $event.button !== 2)
                                  return null
                                return _vm.focusExtraOptions.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [_c("v-icon", [_vm._v("far fa-ellipsis-v")])],
                      1
                    ),
                  ]
                },
              }
            : null,
        ],
        null,
        true
      ),
      model: {
        value: _vm.showMenu,
        callback: function ($$v) {
          _vm.showMenu = $$v
        },
        expression: "showMenu",
      },
    },
    [
      _c(
        "div",
        { staticClass: "editor-actions" },
        [
          _c(
            "v-list",
            { ref: "scrollableList", attrs: { dense: "" } },
            [
              _c(
                "div",
                {
                  staticClass: "text-center font-weight-bold mb-1 mx-1",
                  style: {
                    color: _vm.$vuetify.theme.currentTheme.info,
                    "font-size": "12px",
                    "max-width": "280px",
                  },
                },
                [_vm._v(_vm._s(_vm.formattedDate))]
              ),
              _vm.userMode === "T"
                ? [
                    _c("v-divider", { staticStyle: { margin: "10px 0px" } }),
                    _vm._l(_vm.filteredActions, function (item, i) {
                      return _c(
                        "v-list-item",
                        {
                          key: i,
                          attrs: { link: "" },
                          on: {
                            click: function ($event) {
                              return _vm.doAction(item)
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "mr-3" },
                            [
                              _c("v-icon", {
                                domProps: { textContent: _vm._s(item.icon) },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                staticStyle: { width: "160px" },
                                domProps: {
                                  textContent: _vm._s(_vm.$t(item.label)),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-action",
                            [
                              item.id == "forward" && item.subAction
                                ? [
                                    _c(
                                      "div",
                                      { staticClass: "editor-sub-action" },
                                      [
                                        _c("plus-minus-field", {
                                          ref: "extraOption" + i,
                                          refInFor: true,
                                          on: { blur: _vm.focusActionButton },
                                          model: {
                                            value: _vm.forward,
                                            callback: function ($$v) {
                                              _vm.forward = $$v
                                            },
                                            expression: "forward",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                              item.id == "print" && item.subAction
                                ? [
                                    _c(
                                      "div",
                                      {
                                        ref: "extraOption" + i,
                                        refInFor: true,
                                        staticClass:
                                          "editor-sub-action copy-action",
                                        attrs: { tabindex: "0" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.doPrintOptions.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                          keydown: [
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              return _vm.doPrintOptions.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "left",
                                                  37,
                                                  $event.key,
                                                  ["Left", "ArrowLeft"]
                                                )
                                              )
                                                return null
                                              if (
                                                "button" in $event &&
                                                $event.button !== 0
                                              )
                                                return null
                                              return _vm.focusActionButton.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "up",
                                                  38,
                                                  $event.key,
                                                  ["Up", "ArrowUp"]
                                                )
                                              )
                                                return null
                                              return _vm.focusActionButton.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "down",
                                                  40,
                                                  $event.key,
                                                  ["Down", "ArrowDown"]
                                                )
                                              )
                                                return null
                                              return _vm.focusActionButton.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("div", [_vm._v("Options")]),
                                        _c("i", {
                                          staticClass: "fal fa-angle-right",
                                        }),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c("pb-confirm", { ref: "confirm" }),
      _c("move-lesson-action", { ref: "moveAction" }),
      _c("link-lesson-action", { ref: "linkAction" }),
      _c("print-options", { ref: "printOptionsAction" }),
      _c("lesson-manage-status", { ref: "manageStatus" }),
      _vm.isAddingNoSchool
        ? _c("event-editor-form", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: false,
                expression: "false",
              },
            ],
            ref: "eventEditorForm",
            attrs: { input: _vm.noSchoolEventInput },
          })
        : _vm._e(),
      _c(
        "pb-base-modal",
        {
          attrs: {
            input: _vm.input,
            expandable: false,
            maxWidth: 500,
            withActions: false,
          },
          on: { apply: _vm.applyGoogleEvents },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_vm._v(_vm._s(_vm.$t("manageGoogleEventsLabel")))]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.localShowManageGoogleEvents,
            callback: function ($$v) {
              _vm.localShowManageGoogleEvents = $$v
            },
            expression: "localShowManageGoogleEvents",
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "v-list",
                { attrs: { dense: "" } },
                _vm._l(_vm.localGoogleEvents, function (item) {
                  return _c(
                    "v-list-item",
                    { key: item.googleId, attrs: { dense: "" } },
                    [
                      _c(
                        "v-list-item-icon",
                        { staticClass: "mr-2" },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                small: "",
                                color: _vm.getColor(item.eventColor),
                              },
                            },
                            [_vm._v("fas fa-circle")]
                          ),
                        ],
                        1
                      ),
                      _c("v-list-item-title", [
                        _vm._v(_vm._s(item.eventTitle)),
                      ]),
                      _c(
                        "v-list-item-action",
                        { staticClass: "my-0" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", small: "" },
                              on: {
                                click: function ($event) {
                                  item.hidden = !item.hidden
                                },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { small: "" } },
                                [
                                  item.hidden
                                    ? [_vm._v("fal fa-eye-slash")]
                                    : [_vm._v("fal fa-eye")],
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "pb-base-modal",
        {
          attrs: {
            input: {},
            expandable: false,
            maxWidth: 730,
            withActions: false,
            cardClass: "substitute-day-card",
          },
          on: { apply: _vm.applySubstituteDay },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_vm._v(_vm._s(_vm.$t("substituteDayLabel")))]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.localShowSubstituteDay,
            callback: function ($$v) {
              _vm.localShowSubstituteDay = $$v
            },
            expression: "localShowSubstituteDay",
          },
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "editor" },
              [
                _c(
                  "v-form",
                  { ref: "substituteDayForm" },
                  [
                    _c(
                      "v-container",
                      { staticStyle: { "max-width": "initial" } },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "auto" } },
                              [
                                _c(
                                  "v-radio-group",
                                  {
                                    staticClass: "pt-0 mt-0",
                                    attrs: {
                                      "hide-details": "",
                                      row: "",
                                      dense: "",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("keepScheduleConfirm")
                                                )
                                              ),
                                            ]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.localKeepSchedule,
                                      callback: function ($$v) {
                                        _vm.localKeepSchedule = $$v
                                      },
                                      expression: "localKeepSchedule",
                                    },
                                  },
                                  [
                                    _c("v-radio", {
                                      attrs: {
                                        value: true,
                                        label: _vm.$t("yesLabel"),
                                      },
                                    }),
                                    _c("v-radio", {
                                      attrs: {
                                        value: false,
                                        label: _vm.$t("noLabel"),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "auto" } },
                              [
                                _c(
                                  "v-radio-group",
                                  {
                                    staticClass: "pt-0 mt-0",
                                    attrs: {
                                      "hide-details": "",
                                      row: "",
                                      dense: "",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("bumpLessonsConfirm")
                                                ) + "?"
                                              ),
                                            ]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.localBumpDay,
                                      callback: function ($$v) {
                                        _vm.localBumpDay = $$v
                                      },
                                      expression: "localBumpDay",
                                    },
                                  },
                                  [
                                    _c("v-radio", {
                                      attrs: {
                                        value: true,
                                        label: _vm.$t("yesLabel"),
                                      },
                                    }),
                                    _c("v-radio", {
                                      attrs: {
                                        value: false,
                                        label: _vm.$t("noLabel"),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-0 mt-2",
                                staticStyle: {
                                  "border-top": "1px solid #cccccc",
                                },
                                attrs: { cols: "12" },
                              },
                              [
                                _c("pb-text-editor", {
                                  attrs: { config: { height: "300px" } },
                                  model: {
                                    value: _vm.localSubstituteNotes,
                                    callback: function ($$v) {
                                      _vm.localSubstituteNotes = $$v
                                    },
                                    expression: "localSubstituteNotes",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }