
















































import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import moment from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import DayActions from '../actions/DayActions.vue';
import DayCountHorizontal from './DayCountHorizontal.vue';

const plans = namespace('plans');
const settings = namespace('settings');
const teachers = namespace('teachers');

@Component({
  components: {
    DayActions,
    DayCountHorizontal
  }
})
export default class DayHeader extends Vue {
    @Prop({ type: [Object, Number], required: true, default: {} })
    day!: any;

    @plans.State
    viewType!: string;

    @settings.Getter('getUserMode')
    userMode!: string;

    @settings.Getter('getDateFormat')
    dateFormat!: number;

    @plans.Getter('isHorizontal')
    isHorizontal!: boolean;

    @settings.Getter
    isCycleSchedule!: boolean;

    @settings.Getter('getClassCycle')
    classCycle!: string;

    @settings.Getter('getGlobalLanguage')
    globalLanguage!: any;

    @teachers.Getter
    isCurrentTeacherCycleSchedule!: boolean;

    @teachers.Getter('getCurrentTeacherClassCycle')
    currentTeacherClassCycle!: string;

    @settings.Getter
    isReduceBrightnessOfPastDays!: boolean;

    @plans.Action
    gotoLessonsEvents!: (param: Date) => Promise<any>;

    @plans.Action
    prevLessonsEvents!: (param?: any) => Promise<any>;

    @plans.Action
    nextLessonsEvents!: (param?: any) => Promise<any>;

    @plans.Action
    changeViewType!: (viewType?: string) => Promise<any>;

    @plans.State
    currentDay!: string;

    gotoDateMenu = false;
    localRefreshKey = '';

    get pickerGotoDate() {
      if (CommonUtils.hasText(this.currentDay)) {
        return moment(this.currentDay, 'MM/DD/YYYY').format('YYYY-MM-DD');
      }
      return '';
    }

    get dayName() {
      return this.day.name || '&nbsp;'
    }

    get date() {
      return this.day.date;
    }

    get dayCount() {
      return this.day.count;
    }

    get localDay() {
      return this.format(this.date, 'ddd', '...');
    }

    get localMonth() {
      return this.format(this.date, 'MMM', '...');
    }

    get localDisplayDate() {
      if (this.dateFormat === 2) {
        return this.format(this.date, 'D-MMM', '....');
      }
      return this.format(this.date, 'MMM D', '....');
    }

    get dayString() {
      return 'day' + (moment(this.date).day() + 1);
    }

    get isPast() {
      return DateTimeUtils.isPast(this.day.date);
    }

    get showCycle() {
      return (this.userMode === 'A' && this.isCurrentTeacherCycleSchedule) || (this.userMode === 'T' && this.isCycleSchedule)
    }

    get twoWeekCycle() {
      return (this.userMode === 'A' && this.currentTeacherClassCycle === 'twoWeeks') || (this.userMode === 'T' && this.classCycle === 'twoWeeks')
    }

    get getGlobalLanguage() {
      return this.globalLanguage || 'en';
    }

    @Watch('gotoDateMenu')
    focusDatePicker() {
      if (this.gotoDateMenu) {
        this.$nextTick(() => {
          new Promise(resolve => setTimeout(resolve, 100)).then(() => {
            try {
              (this.$refs as any).datePicker.$el.querySelector('.v-date-picker-header__value').querySelector('button').focus()
            } catch (e) {
              console.log(this.$t('focusFailed'));
            }
          });
        });
      }
    }

    format(dateString: string, format: string, defaultValue: string) {
      const date = moment(dateString, 'MM/DD/YYYY', true);
      if (date.isValid()) {
        return date.format(format);
      } else {
        return defaultValue;
      }
    }

    doChangeViewType(viewType: string) {
      this.changeViewType(viewType).then(result => {
        if (result.changed) {
          this.gotoLessonsEvents(moment(this.date, 'MM/DD/YYYY').toDate());
          this.$eventBus.$emit('viewTypeChanged');
        }
      });
    }

    onPickerFirstDayChanged(value: string) {
      this.gotoDateMenu = false;
      this.gotoLessonsEvents(moment(value, 'YYYY-MM-DD').toDate());
    }

    showDayActions() {
      this.$eventBus.$emit('dayHeaderRightClicked', this.localRefreshKey);
    }

    mounted() {
      this.localRefreshKey = CommonUtils.generateUUID();
    }
}
