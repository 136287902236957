var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.count > 0
      ? _c(
          "div",
          {
            staticClass: "day-count-vertical d-flex",
            class: {
              past: _vm.isPast,
              "reduce-brightness": _vm.isReduceBrightnessOfPastDays
            }
          },
          [
            _c(
              "v-menu",
              {
                attrs: { "offset-y": "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "px-0",
                                  attrs: {
                                    elevation: "0",
                                    text: "",
                                    "x-small": ""
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-1",
                                  attrs: { "x-small": "" }
                                },
                                [_vm._v("fal fa-chevron-down")]
                              ),
                              _c("span", { staticClass: "pr-2" }, [
                                _vm._v(_vm._s(_vm.countTypeLabel) + ":")
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3150018531
                )
              },
              [
                _c(
                  "v-list",
                  _vm._l(_vm.countItems, function(item, index) {
                    return _c(
                      "v-list-item",
                      {
                        key: index,
                        class: {
                          "primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted":
                            item.value === _vm.countType
                        },
                        on: {
                          click: function($event) {
                            return _vm.itemChanged(item)
                          }
                        }
                      },
                      [_c("v-list-item-title", [_vm._v(_vm._s(item.text))])],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "d-flex",
                staticStyle: { "flex-grow": "1" },
                attrs: { "aria-hidden": "true" }
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "v-chip",
                      {
                        staticClass: "text-center px-1 white--text",
                        staticStyle: { height: "20px" },
                        attrs: {
                          "x-small": "",
                          color: _vm.highContrastMode ? "primary" : "#669ad9"
                        }
                      },
                      [_vm._v(_vm._s(_vm.count))]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { "flex-grow": "1" } },
                  [
                    _c("v-slider", {
                      staticClass: "pa-0 ma-0",
                      attrs: {
                        tabindex: "-1",
                        "track-color": "#e5e5e5",
                        color: "#e5e5e5",
                        "thumb-color": _vm.highContrastMode
                          ? "primary"
                          : "#669ad9",
                        max: "100",
                        min: "0",
                        value: _vm.percentage,
                        "hide-details": "",
                        readonly: ""
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "v-chip",
                      {
                        staticClass: "text-center px-1",
                        staticStyle: { height: "20px" },
                        attrs: {
                          "x-small": "",
                          color: _vm.$vuetify.theme.dark
                            ? "blue-grey-darken-2"
                            : "#e5e5e5"
                        }
                      },
                      [_vm._v(_vm._s(_vm.remaining))]
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        )
      : _vm.count === -1
      ? _c(
          "div",
          {
            staticClass: "text-center",
            class: {
              past: _vm.isPast,
              "reduce-brightness": _vm.isReduceBrightnessOfPastDays
            }
          },
          [_vm._v(" " + _vm._s(_vm.$t("noSchoolLabel")) + " ")]
        )
      : _c("div"),
    _vm.showDayName ? _c("div", [_vm._v(_vm._s(_vm.dayName))]) : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }