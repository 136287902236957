import { render, staticRenderFns } from "./DayHeader.vue?vue&type=template&id=3a4f201a&scoped=true&"
import script from "./DayHeader.vue?vue&type=script&lang=ts&"
export * from "./DayHeader.vue?vue&type=script&lang=ts&"
import style0 from "./DayHeader.vue?vue&type=style&index=0&id=3a4f201a&scoped=true&lang=css&"
import style1 from "./DayHeader.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a4f201a",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VDatePicker,VIcon,VMenu})


/* hot reload */
if (module.hot) {
  var api = require("/Users/adriandaluz/Development/git/planbook-web-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3a4f201a')) {
      api.createRecord('3a4f201a', component.options)
    } else {
      api.reload('3a4f201a', component.options)
    }
    module.hot.accept("./DayHeader.vue?vue&type=template&id=3a4f201a&scoped=true&", function () {
      api.rerender('3a4f201a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/plans/common/DayHeader.vue"
export default component.exports