
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ld from 'lodash';
import { PlanbookStorage } from '@/common/planbook-storage';

@Component
export default class DayCount extends Vue {
  @Prop({ type: [Object, Number], required: true, default: {} })
  day!: any;

  @Prop({ type: String, required: true, default: '' })
  dayString!: any;

  countType = 1;
  dayNum = 0;

  get dayCountTypes() {
    return PlanbookStorage.get('dayCountTypes') || [1, 1, 1, 1, 1, 1, 1];
  }

  get count(): number {
    switch (this.countType) {
      case 4:
        return this.gradingPeriodWeekCount;
      case 3:
        return this.gradingPeriodCount;
      case 2:
        return this.weekCount;
    }
    return this.dayCount;
  }

  get total(): number {
    switch (this.countType) {
      case 4:
        return this.gradingPeriodWeekTotal;
      case 3:
        return this.gradingPeriodTotal;
      case 2:
        return this.weekTotal;
    }
    return this.dayTotalCount;
  }

  get remaining() {
    return this.total - this.count;
  }

  get percentage() {
    return (this.count / this.total) * 100;
  }

  get dayCount() {
    return this.day.count;
  }

  get dayTotalCount() {
    return this.day.total;
  }

  get weekCount(): number {
    return this.day.weekCount;
  }

  get weekTotal(): number {
    return this.day.weekTotal;
  }

  get dayGradingPeriod() {
    return this.day.gradingPeriodCount || {};
  }

  get gradingPeriodName() {
    return this.dayGradingPeriod.name;
  }

  get gradingPeriodCount() {
    return this.dayGradingPeriod.count;
  }

  get gradingPeriodTotal() {
    return this.dayGradingPeriod.total;
  }

  get gradingPeriodWeekCount() {
    return this.dayGradingPeriod.weekCount;
  }

  get gradingPeriodWeekTotal() {
    return this.dayGradingPeriod.weekTotal;
  }

  get cycleDayNum() {
    return this.day.cycleDayNum;
  }

  get withGradingPeriod() {
    return this.dayGradingPeriod && this.dayGradingPeriod.count > 0;
  }

  get countTypeLabel() {
    return (this.countItems.find(i => i.value === this.countType) || { value: -1, text: this.$t('n/aLabel') }).text;
  }

  get countItems() {
    const items = [
      {
        text: this.$t('dayLabel'),
        value: 1
      },
      {
        text: this.$t('weekLabel'),
        value: 2
      }
    ];
    if (this.withGradingPeriod) {
      items.push({
        text: this.gradingPeriodName + ' ' + this.$t('dayLabel'),
        value: 3
      });
      items.push({
        text: this.gradingPeriodName + ' ' + this.$t('weekLabel'),
        value: 4
      });
    }
    return items;
  }

  @Watch('dayString')
  onDayStringChange() {
    this.init()
  }

  itemChanged(item: any) {
    this.countType = item.value;

    if (this.dayString !== '') {
      const localDayCountTypes = ld.cloneDeep(this.dayCountTypes);
      localDayCountTypes[this.dayNum] = this.countType;
      PlanbookStorage.set('dayCountTypes', localDayCountTypes);
    }
  }

  init() {
    if (this.dayString === 'day1') {
      this.dayNum = 0;
      this.countType = this.dayCountTypes[this.dayNum];
    } else if (this.dayString === 'day2') {
      this.dayNum = 1;
      this.countType = this.dayCountTypes[this.dayNum];
    } else if (this.dayString === 'day3') {
      this.dayNum = 2;
      this.countType = this.dayCountTypes[this.dayNum];
    } else if (this.dayString === 'day4') {
      this.dayNum = 3;
      this.countType = this.dayCountTypes[this.dayNum];
    } else if (this.dayString === 'day5') {
      this.dayNum = 4;
      this.countType = this.dayCountTypes[this.dayNum];
    } else if (this.dayString === 'day6') {
      this.dayNum = 5;
      this.countType = this.dayCountTypes[this.dayNum];
    } else if (this.dayString === 'day7') {
      this.dayNum = 6;
      this.countType = this.dayCountTypes[this.dayNum];
    }
  }

  created() {
    this.init();
  }
}
